.testimonial-container {
  position: relative;
  /* width: 300px; */
  /* height: 70px; */
  /* background: black; */
  /* border-radius: 12px; */
  /* overflow: hidden; */
}

.testimonial-container:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 78px 78px 0;
  border-color: transparent #0FBDA9 transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
}