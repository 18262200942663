.payment-table tr td {
  padding: 0.8rem 1rem;
  background: #fafafa;
}

.payment-table tr:not(:last-child) td {
  padding: 0.8rem 1rem;
}

.payment-table tr td:last-child { text-align: right; }
