.bg-gradient-green {
  background: rgb(11,142,127);
  background: linear-gradient(0deg, rgba(11,142,127,1) 0%, rgba(12,164,146,1) 35%, rgba(15,189,169,1) 100%);
}

.yellow-text {
  color: #F1CC4A !important;
}

.banner-course-height-tablet {
  height: 500px;
}

@media only screen and (max-width: 600px) {
  .banner-course-height-tablet {
    height: 90vw;
  }
  .banner-course-svg-bottom svg {
    width: 90% !important;
  }
  .banner-course-img {
    width: 93% !important;
  }
}

.banner-course-img {
  width: 100%;
  max-height: 24rem;
  max-width: 24rem;
  aspect-ratio: 1 / 1;
  margin-right: 100px;
}

.banner-course-svg-top svg {
  width: 100%;
  height: 100%;
  max-width: 410px;
  max-height: 453px;
}

.banner-course-svg-bottom svg {
  width: 100%;
  height: 100%;
  max-width: 380px;
  max-height: 380px;
}