.react-code-input {
  @apply flex justify-between space-x-3 !important;
}

.react-code-input input {
  @apply border-0 w-full appearance-none bg-gray-100 h-14 rounded-md font-primary m-0 text-lg text-center !important;
}

.react-code-input input::-webkit-outer-spin-button,
.react-code-input input::-webkit-inner-spin-button {
  @apply appearance-none m-0;
}
