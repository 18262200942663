.banner-img {
  width: 100%;
  /* max-height: 400px; */
  max-width: 580px;
  /* border-radius: 250px 250px 200px 250px; */
  /* aspect-ratio: 12 / 6; */
}

#mask0_113_585 {
  mask-type: alpha;
}

.banner-height-tablet {
  height: 550px;
}

@media only screen and (max-width: 600px) {
  .banner-img{
    min-height: 260px;
    min-width: 340px;
  }

  .banner-height-tablet {
    height: 100vw;
  }

}

@media only screen and (max-width: 400px) {
  .banner-img{
    min-height: 260px;
    min-width: 340px;
    margin-left: -20px !important;
  }

  .content-banner {
    margin-top: -50px;
  }

  .banner-height-tablet {
    height: 100vw;
  }

}

.banner-svg-top svg {
  width: 100%;
  height: 100%;
  max-width: 490px;
  max-height: 490px;
}

.banner-svg-bottom svg {
  width: 100%;
  height: 100%;
  max-width: 410px;
  max-height: 400px;
}
