input[type='radio'] {
  @apply cursor-pointer border-primary;
}

input[type='radio']:checked {
  @apply ring-transparent border-transparent bg-primary ring-2 ring-white !important;
}

input[type='radio']:focus {
  @apply ring-0 !important;
}
