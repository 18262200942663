.grid-container-sm {
  grid-template-areas:
  'home' 
  'contact'
  'desc';
}
.grid-container-md {
  grid-template-areas:
  'home contact'
  'desc desc';
}
.desc {
  grid-area: desc;
}
.contact {
  grid-area: contact;
}
.home {
  grid-area: home;
}