.position-floating {
  z-index: 99;
}

@media only screen and (max-width: 400px){
 .position-floating {
  margin-left: -400px;
 }
 .styles-module_whatsappButton__IWx9V{
  margin-left: -100px;
 }
}
